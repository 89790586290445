import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import ContentFold from '../components/page-content/content-fold'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <ContentFold
      title="Something's wrong here."
      className="p-5 my-5 has-text-centered"
    >
      We can't find the page you requested.
      <br />
      <Link to="/" className="button is-primary mt-3">
        Back to Home
      </Link>
    </ContentFold>
  </Layout>
)

export default NotFoundPage
